<template>
  <div class="module_one_block">
    <div class="module_one_header_block">
      <div class="module_one_header_container">
        <div class="module_one_header_label">
          {{ $t('Notifications') }}
        </div>
      </div>
    </div>
    <div class="module_one_main_block">
      <div class="module_one_main_container">
        <div class="module_one_content_container">
          <div class="module_one_plot">
            <div class="module_one_plot_module_icon"
                 :class="{'notifications_plot_module_icon': showNotificationsSettings === true}"
                 @click="showActiveTab('settings')"
            >
              {{ $t('Notification_settings') }}
            </div>
            <div class="module_one_plot_sort_filter">
              {{ $t('Sort_and_filter') }}
            </div>
            <div class="module_one_plot_module"
                 :class="{'module_one_plot_about': showNotificationsNew === true}"
                 @click="showActiveTab('new')">
              {{ $t('New') }}
            </div>
            <div class="module_one_plot_module"
                 :class="{'module_one_plot_about': showNotificationsRead === true}"
                 @click="showActiveTab('read')">
              {{ $t('Read') }}
            </div>
<!--            <div class="module_one_plot_filter">-->
<!--              Фильтрация-->
<!--            </div>-->
<!--            <a href="#" class="module_one_plot_module_date_icon" @click="showCalendarFunc">-->
<!--              Дата поступления-->
<!--              <div v-show="showCalendar" class="notifications_calendar" @click="showCalendar = false">-->
<!--                &lt;!&ndash;                <b-calendar v-model="value"&ndash;&gt;-->
<!--                &lt;!&ndash;                            @context="onContext"&ndash;&gt;-->
<!--                &lt;!&ndash;                            locale="en-US"&ndash;&gt;-->
<!--                &lt;!&ndash;                            class="notifications_calendar"&ndash;&gt;-->
<!--                &lt;!&ndash;                ></b-calendar>&ndash;&gt;-->
<!--                <date-picker-->
<!--                    v-model="date"-->
<!--                    color="pink"-->
<!--                    :attributes='attributes'-->
<!--                />-->
<!--              </div>-->
<!--            </a>-->
<!--            <div class="module_one_plot_module_time_icon" @click="showClockFunc">-->
<!--              Время поступления-->
<!--              <div v-show="showClock" @click="showClock = false">-->
<!--                &lt;!&ndash;                <b-time&ndash;&gt;-->
<!--                &lt;!&ndash;                    id="notifications_clock"&ndash;&gt;-->

<!--                &lt;!&ndash;                    class="notifications_clock"&ndash;&gt;-->
<!--                &lt;!&ndash;                ></b-time>&ndash;&gt;-->
<!--                <div class="notifications_time">-->
<!--                  <select class="notifications_time_hour" v-model="selectedHour">-->
<!--                    <option v-for="hour in hours">{{ hour }}</option>-->
<!--                  </select>-->
<!--                  <div class="notifications_time_separation">:</div>-->
<!--                  <select class="notifications_time_minute" v-model="selectedMinute">-->
<!--                    <option v-for="minute in minutes">{{ minute }}</option>-->
<!--                  </select>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
        <div class="notifications_desc" v-if="showNotificationsNew">
          <div class="notifications_desc_btn" v-if="notifications.length !== 0">
            <a class="notifications_desc_btn_read_all" @click="readAll">{{ $t('Read_all') }}</a>
          </div>
          <div class="notifications_desc_item" v-for="notification in notifications">
            <div class="notifications_desc_item_label">
              SMART OKU
            </div>
            <div class="notifications_desc_item_text">
              <div class="notifications_desc_item_text_mark" @click="read(notification.id)">{{ $t('Mark_as_read') }}</div>
              <div class="notifications_desc_item_title">{{ notification.data.header }}</div>
              <div class="notifications_desc_item_message">{{ notification.data.message }}</div>
              <div class="notifications_desc_item_time">{{ formatDate(notification.created_at) }}</div>
            </div>
          </div>
          <MyPagination
            :totalPages="Math.ceil(total / perPage)"
            :total="total"
            :perPage="perPage"
            :currentPage="currentPage"
            @pagechanged="onPageChange"
          />
        </div>

        <div class="notifications_read_desc_block" v-if="showNotificationsRead">
          <div class="notifications_desc_item" v-for="notification in notifications">
            <div class="notifications_desc_item_label">
              SMART OKU
            </div>
            <div class="notifications_desc_item_text">
              <div class="notifications_desc_item_title">{{ notification.data.header }}</div>
              <div class="notifications_desc_item_message">{{ notification.data.message }}</div>
              <div class="notifications_desc_item_time">{{ formatDate(notification.created_at) }}</div>
            </div>
          </div>
          <MyPagination
            :totalPages="Math.ceil(total / perPage)"
            :total="total"
            :perPage="perPage"
            :currentPage="currentPage"
            @pagechanged="onPageChange"
          />
        </div>

        <div class="notifications_read_desc" v-if="showNotificationsSettings">
          <div class="notifications_desc_btn">
          </div>
          <div class="notifications_settings_block">
            <div class="notifications_settings_checkboxes">
              <div class="notifications_settings_checkbox_label">
                {{ $t('Allow_notifications') }}
              </div>
              <div class="switch-form-main">
                <div>{{ $t('System') }}</div>
                <div><label class="form-switch">
                  <input type="checkbox" v-model="checkedSystemSettings" @change="updateNotificationSettings">
                  <i></i>
                </label></div>
              </div>
              <!--              <div class="switch-form-main">-->
              <!--                <div>СМС уведомления</div>-->
              <!--                <div><label class="form-switch">-->
              <!--                  <input type="checkbox" v-model="checkedSmsSettings">-->
              <!--                  <i></i>-->
              <!--                </label></div>-->
              <!--              </div>-->
              <div class="switch-form-main">
                <div>{{ $t('Email') }}</div>
                <div><label class="form-switch">
                  <input type="checkbox" v-model="checkedEmailSettings" @change="updateNotificationSettings">
                  <i></i>
                </label></div>
              </div>
            </div>
            <div class="notifications_settings_lang">
              <div class="notifications_settings_lang_title">{{ $t('Notification_language') }}</div>
              <div class="notifications_settings_lang_checkbox">
                <input v-model="inputLangValue"
                       @click="showSettingsLang = !showSettingsLang"
                       readonly
                       @blur="closeLangList"
                />
                <div v-if="showSettingsLang" class="notifications_settings_lang_list">
                  <div class="notifications_settings_lang_list_item" @click="newLang('Русский', 'ru')">{{$t('ru')}}</div>
                  <div class="notifications_settings_lang_list_item" @click="newLang('Казахский', 'kz')">{{$t('kz')}}</div>
                  <div class="notifications_settings_lang_list_item" @click="newLang('Английский', 'en')">Английский</div>
                </div>
              </div>
              <div class="notifications_settings_lang_checkbox_img"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import MyPagination from "@/components/MyPagination";

export default {
  name: "NotificationsNew",
  components: {
    MyPagination,
    DatePicker
  },
  data() {
    return {
      readPage: false,
      checkedSmsSettings : false,
      showSettingsLang : false,
      inputLangValue : 'Русский',
      inputLangValueShort : 'ru',
      checkedEmailSettings : false,
      checkedSystemSettings : false,
      showNotificationsNew : true,
      showNotificationsRead : false,
      showNotificationsSettings : false,
      value: '',
      context: null,
      showCalendar: false,
      showClock: false,
      // currentPage : 3,
      // rows : 5,
      // perPage : 1,
      date: new Date(),
      selectedHour: '00',
      selectedMinute: '00',
      attributes: [
        {
          key: 'today',
          highlight: {
            style: {
              background: '#C460C0',
              color: '#FFFFFF',
            },
            contentStyle: {
              color: '#FFFFFF',
            },
          },
          dates: new Date(),
        },
      ],
      notifications: [],
      currentPage: 1,
      total: 0,
      perPage: 10,
    }
  },
  mounted() {
    this.getUserNotificationSettings();
    this.getUserNotifications();
  },
  methods: {
    getUserNotificationSettings() {
      this.$http
        .get(`${API_ROOT}/api/user`)
        .then(({ body }) => {
          const { notification_settings } = body;

          this.checkedSystemSettings = notification_settings?.system ?? 1;
          this.checkedSmsSettings = notification_settings?.sms ?? 1;
          this.checkedEmailSettings = notification_settings?.email ?? 1;
          this.inputLangValueShort = notification_settings?.language ?? "ru";
          this.inputLangValue = notification_settings ?
            notification_settings.language === "ru"
              ? "Русский"
              : notification_settings.language === "kz"
                ? "Казахский"
                : "Английский"
            : "Русский";
        });
    },
    getUserNotifications(readed = false) {
      this.readPage = readed;
      this.$http
        .get(`${API_ROOT}/api/user/notifications`, { params: { readed } })
        .then(({ body }) => {
          this.notifications = body.data;
          this.currentPage = body.current_page;
          this.total = body.total;
          this.perPage = body.per_page;
        });
    },
    async readAll() {
      await this.$http.post(`${API_ROOT}/api/user/notifications/read/all`);
      await this.getUserNotifications(false);
    },
    async read(id) {
      await this.$http.put(`${API_ROOT}/api/user/notifications/read/${id}`);
      await this.getUserNotifications(false);
    },
    formatDate(value){
      if (value) {
        return moment(String(value)).format('HH:mm DD.MM.YYYY')
      }
    },
    newLang(lang, langShort) {
      this.inputLangValue = lang
      this.inputLangValueShort = langShort
      this.showSettingsLang = false
      this.updateNotificationSettings();
    },
    closeLangList() {
      setTimeout(() => {
        this.showSettingsLang = false
      }, 300)
    },
    showActiveTab(tab) {
      if (tab === 'new') {
        this.showNotificationsNew = true
        this.showNotificationsRead = false
        this.showNotificationsSettings = false
        this.getUserNotifications(false);

      } else if (tab === 'read') {
        this.showNotificationsNew = false
        this.showNotificationsRead = true
        this.showNotificationsSettings = false
        this.getUserNotifications(true);

      } else if (tab === 'settings') {
        this.showNotificationsNew = false
        this.showNotificationsRead = false
        this.showNotificationsSettings = true
      }
    },
    onContext(ctx) {
      this.context = ctx
    },
    onPageChange(page) {
      let params = {
        page,
        readed: this.readPage
      }
      this.$http.get(`${API_ROOT}/api/user/notifications`, { params })
        .then(({ body }) => {
          this.notifications = body.data;
          this.currentPage = body.current_page;
          this.total = body.total;
        });
    },
    showClockFunc() {
      this.showClock = !this.showClock
      this.showCalendar = false
    },
    showCalendarFunc() {
      this.showCalendar = !this.showCalendar
      this.showClock = false
    },
    updateNotificationSettings() {
      this.$http.post(API_ROOT + "/api/user/notification-settings", {
        system: this.checkedSystemSettings,
        sms: this.checkedSmsSettings,
        email: this.checkedEmailSettings,
        language: this.inputLangValueShort,
      });
    },
  },
  computed: {
    hours() {
      const rangeHours = [];

      for (
          let i = 0;
          i <= 23;
          i++
      ) {
        i < 10 ? rangeHours.push("0" + i) : rangeHours.push("" + i)
      }

      return rangeHours;
    },
    minutes() {
      const rangeMinutes = [];

      for (
          let i = 0;
          i <= 59;
          i++
      ) {
        i < 10 ? rangeMinutes.push("0" + i) : rangeMinutes.push("" + i)
      }

      return rangeMinutes;
    }
  }
}
</script>

<style scoped>

</style>
